import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { TranslocoService } from '@jsverse/transloco';
import { take } from 'rxjs/operators';

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
    imports: [MatButtonModule, MatMenuModule, MatIconModule],
})
export class LanguagesComponent implements OnInit, OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _fuseNavigationService = inject(FuseNavigationService);
    private _translocoService = inject(TranslocoService);

    availableLangs: { id: string; label: string }[];
    activeLang: string;
    flagCodes: any;

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the available languages from transloco
        const _availableLangs = this._translocoService.getAvailableLangs();
        // if (typeof _availableLangs[0] === 'LangDefinition')
        // {
        //     this.availableLangs = _availableLangs;
        // }
        this.availableLangs = _availableLangs.map((item) => {
            return { id: item, label: this._getLabel(item) };
        });

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {
            // Get the active lang
            this.activeLang = activeLang;

            // Update the navigation
            this._updateNavigation(activeLang);
        });

        // Set the country iso codes for languages for flags
        this.flagCodes = {
            en: {
                label: 'English',
                code: 'us',
            },
            tr: {
                label: 'Turkish',
                code: 'tr',
            },
        };
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void {
        // Set the active lang
        this._translocoService.setActiveLang(lang);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private _getLabel(id: string) {
        switch (id) {
            case 'en':
                return 'English';
            default:
                return id;
        }
    }
    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                'mainNavigation'
            );

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        this._translateLabels(navigation, navComponent);

        // navigation.forEach((navigationItem: FuseNavigationItem) =>
        // {
        //     if (navigationItem.translation)
        //     {
        //         this._translocoService.selectTranslate(navigationItem.translation).pipe(take(1))
        //             .subscribe((translation) =>
        //             {

        //                 // Set the title
        //                 navigationItem.title = translation;
        //                 //projectDashboardItem.title = translation;

        //                 // Refresh the navigation component
        //                 navComponent.refresh();
        //             });
        //     }
        // });

        // Get the Project dashboard item and update its title
        //const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);
        // if (projectDashboardItem)
        // {
        //     this._translocoService.selectTranslate('Project').pipe(take(1))
        //         .subscribe((translation) =>
        //         {

        //             // Set the title
        //             projectDashboardItem.title = translation;

        //             // Refresh the navigation component
        //             navComponent.refresh();
        //         });
        // }

        // // Get the Analytics dashboard item and update its title
        // const analyticsDashboardItem = this._fuseNavigationService.getItem('dashboards.analytics', navigation);
        // if (analyticsDashboardItem)
        // {
        //     this._translocoService.selectTranslate('Analytics').pipe(take(1))
        //         .subscribe((translation) =>
        //         {

        //             // Set the title
        //             analyticsDashboardItem.title = translation;

        //             // Refresh the navigation component
        //             navComponent.refresh();
        //         });
        // }
    }

    private _translateLabels(
        navigationItems: FuseNavigationItem[],
        navComponent: FuseVerticalNavigationComponent
    ): void {
        navigationItems.forEach((navigationItem: FuseNavigationItem) => {
            if (navigationItem.children) {
                this._translateLabels(navigationItem.children, navComponent);
            }
            if (navigationItem.translation) {
                this._translocoService
                    .selectTranslate(navigationItem.translation)
                    .pipe(take(1))
                    .subscribe((translation) => {
                        // Set the title
                        navigationItem.title = translation;

                        // Refresh the navigation component
                        navComponent.refresh();
                    });
            }
        });
    }
}
