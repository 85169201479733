import { ChangeDetectionStrategy, Component, ViewEncapsulation, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AppConfigService } from '@fuse/services/config';

@Component({
    selector: 'app-scheme',
    templateUrl: './scheme.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatButtonModule, MatIconModule],
})
export class SchemeComponent {
    //config service
    configService: AppConfigService = inject(AppConfigService);

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    switchScheme(): void {
        this.configService.switchScheme();
    }
}
