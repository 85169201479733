<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="languages">
    <mat-icon [svgIcon]="'heroicons_outline:language'"></mat-icon>
    <!-- <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: activeLang}"></ng-container> -->
</button>

<!-- Language menu -->
<mat-menu [xPosition]="'before'" #languages="matMenu">
    @for (lang of availableLangs; track trackByFn($index, lang)) {
        <button mat-menu-item (click)="setActiveLang(lang.id)">
            <span class="flex items-center">
                <!-- <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: lang.id}"></ng-container> -->
                <span>{{ lang.label }}</span>
            </span>
        </button>
    }
</mat-menu>

<!-- Flag image template -->
<!-- <ng-template
let-lang
#flagImage
>
<span class="relative w-6 shadow rounded-sm overflow-hidden">
  <span class="ml-3">{{flagCodes[lang].label}}</span>
  <img
    class="w-full"
    [src]="'assets/images/flags/' + flagCodes[lang].code.toUpperCase() + '.svg'"
    [alt]="'Flag image for ' + lang"
    >
  </span>
</ng-template> -->
